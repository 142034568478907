import request from '@/router/axios'

export function fetchTypeTree(params) {
    return request({
        url: '/admin/file/type/tree/type',
        method: 'get',
        params: params
    })
}

export function findList(params) {
    return request({
        url: '/admin/document/find',
        method: 'get',
        params: params
    })
}


export function downloadCheck(id) {
    return request({
        url: '/admin/document/download',
        method: 'get',
        params: {
            id: id
        }
    })
}

/**
 * 获取下载详情
 */
export function findDownloadDetails(id) {
    return request({
        url: '/admin/document/find/' + id,
        method: 'get',
    })
}


/**
 * 下载前置检查
 */
export function downloadFileCheck(id) {
    return request({
        url: '/admin/document/download/check',
        method: 'get',
        params: {
            id: id
        }
    })
}
