<template>
  <div style="width: 1200px">
    <div style="margin-top: 10px;">

      <el-row :gutter="10">
        <div style="color: #666666;margin:20px;font-size: 12px;line-height: 17px;">广州高考网 > 详情</div>

        <el-col span="13">
          <el-card class="card">
            <span>{{ this.document.name }}</span>
            <el-divider/>
            <el-row>
              <el-col span="10">
                <div style="margin: 50px 0 0 110px;">
                  <el-image :src="require('../../../assets/download.png')"/>
                  <span style="color:#0080FF;" @click="download">点击下载</span>
                </div>
              </el-col>
              <el-col :span="10">
                <div style="margin-top: 50px;color:#3D3D3D;font-size: 14px;text-align:center;">
                  文件大小
                  <br>
                  ({{ this.document.fileSize }})
                  <br>
                  下载次数:{{ this.document.downCount }}次
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <el-col span="11">
          <el-card class="card">
            <div style="color:#0080FF;font-size: 16px">会员评论</div>
            <div style="margin-top: 13px">
              <el-divider/>
              <div style="display: flex;justify-content: center">
                <div style="margin-top: 70px">评论：</div>
                <el-input
                    style="width: 85%"
                    v-model="content"
                    type="textarea"
                    :autosize="{ minRows: 7, maxRows: 15 }"
                    placeholder="请输入您的评论"
                ></el-input>
              </div>

              <div style="display: flex;margin-top: 30px">
                <div style="width: 80%">
                  <div style="display:flex;">
                    <div
                        style="margin-top: 10px">验证码：
                    </div>
                    <div style="display: flex">
                      <el-input
                          style="width: 80px;"
                          v-model="codeText"

                      ></el-input>
                      <div @click="refreshCode">
                        <valid-code style="width: 100px;height: 30px" :identifyCode="identifyCode"></valid-code>

                      </div>

                    </div>
                  </div>
                </div>
                <el-button
                    type="primary"
                    size="mini"
                    class="btn"
                    :disabled="!content"
                    @click="postM"

                >提交
                </el-button
                >
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col span="13">
          <el-card class="card" style="margin-top: 10px;">
            <span>资源信息</span>
            <el-divider/>
            {{ this.document.content }}
          </el-card>
        </el-col>
      </el-row>
    </div>

<news></news>
  </div>
</template>

<script>

import {downloadFileCheck, findDownloadDetails} from "../../../api/front/topic";
import {downBlobFile} from "../../../utils";
import ValidCode from "../../../component/ValidCode"
import {postMessage} from "../../../api/user";
import news from "../../../component/news";


export default {
  name: 'Goods',
  components: {
    ValidCode,
    news
  },
  data() {
    return {
      // 当前id
      id: '',
      // 当前id的详细内容
      document: {},
      codeText: "",
      content: "",
      identifyCode: "",
      identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ",
      messageData: {
        documentId: "",
        content: "",
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.refreshCode();
    this.load();

  },
  methods: {
    postM() {
      if (!this.content) {
        this.$message.error("请输入内容");
        return;
      }

      if (this.codeText !== this.identifyCode) {
        this.$message.error("验证码不正确");
        return;
      }
      this.messageData.documentId = this.id;
      this.messageData.content = this.content;
      postMessage(this.messageData).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          this.content = '';
          this.messageData = {};
        }
      })
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    }
    ,
    // 下载
    download() {
      downloadFileCheck(this.id).then(res => {
        if (res.data.code === 1) {
          this.$message.error(res.data.msg);
          return;
        }
        downBlobFile(this.id, this.document.name, this.document.type)
        this.load();
      });
    },
    load() {
      findDownloadDetails(this.id).then(res => {
        this.document = {};
        if (res.data.code === 0) {
          this.document = res.data.data;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
  },
}
</script>

<style scoped  lang="scss">
span {
  color: #333333;
  font-size: 18px;
}

.card {
  height: 330px;
}
.borderHeader {
  color: #0080ff;
  font-size: 18px;
}

.center {
  .border {
    font-size: 18px;
    padding: 15px 15px 9px 15px;
    border-bottom: 1px solid #79bcff;
    background: #e1f0ff;
    color: #0080ff;
  }

  .more {
    float: right;
    font-size: 14px;
    color: #999999;
  }

  .step {
    position: relative;
  }

  .step:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5eacf9;
  }
}

::v-deep {
  .el-divider--horizontal {
    margin: 9px 0 24px 0;
    color: #d8d8d8;
  }

  .el-menu-item {
    padding: 0 5px;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 33px;
    line-height: 33px;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    color: #0080ff;
    border-bottom: 2px solid #0080ff;
  }

  .el-card.is-always-shadow,
  .el-card.is-hover-shadow:focus,
  .el-card.is-hover-shadow:hover {
    box-shadow: none;
  }

  .center {
    .el-card__body {
      padding: 0 0 15px 0;
    }
  }

  .footerBox {
    .el-card__body {
      padding: 15px;
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}

</style>
