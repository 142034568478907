import request from '/src/router/axios'

export function findIp() {
    return request({
        url: '/admin/user/find/ip',
        headers: {
            'from': 'Y'
        },
        method: 'get',
    })
}

export function findCount() {
    return request({
        url: '/admin/member/user/find/visitor/number',
        method: 'get',
    })
}



export function register(obj) {
    return request({
        url: '/admin/member/user/register',
        headers: {
            'from': 'Y'
        },
        method: 'put',
        data: obj
    })
}

export function updateLoginCount() {
    return request({
        url: '/admin/member/user/update/login/count',
        method: 'get',
    })
}

export function updateVisitorNumber() {
    return request({
        url: '/admin/member/user/update/visitor/number',
        method: 'get',
    })
}

export function subMessage(obj) {
    return request({
        url: '/admin/leave/message/tourist',
        headers: {
            'from': 'Y'
        },
        method: 'put',
        data: obj
    })
}

export function getUserBugById(query) {
    return request({
        url: '/admin/order/find/orders',
        method: 'get',
        params: query
    })
}


//根据订单获取二维码
export function getOrderDetail(query) {
    return request({
        url: '/admin/order/create',
        method: 'put',
        params: query
    })
}

export function postMessage(data) {
    return request({
        url: '/admin/leave/message/file/message',
        method: 'put',
        data: data
    })
}

// 校验订单是否已付款
export function checkCreatOrder(query) {
    return request({
        url: '/admin/order/check/order/status',
        method: 'get',
        params: query
    })
}

// 使用订单
export function useOrder(query) {
    return request({
        url: '/admin/order/use/order',
        method: 'put',
        params: query
    })
}


