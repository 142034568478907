<template>
  <div style="margin-top: 10px" class="center">
    <el-row :gutter="10">
      <el-col :span="12" v-for="item in recommend">
        <el-card
            style="height: 250px; margin-top: 10px; border: 1px solid #79bcff"
        >
          <div class="border">
            <i class="el-icon-tickets"></i>
            <span
                @click="goToList(item.sysConsultType.id)"
                style="cursor: pointer;">
                {{ item.sysConsultType.name }}
              </span>
            <span
                style="color: #5eacf9;cursor: pointer;"
                class="more"
                @click="goToList(item.sysConsultType.id)"
            >更多···</span
            >
          </div>

          <div style="padding: 12px 15px 0 15px">
            <el-row :gutter="10">
              <el-col
                  :span="24"
                  v-for="content in item.sysConsults"
                  :key="item.id"
                  style="margin-bottom: 10px"
              >
                <div class="step">
                    <span style="padding-left: 13px">
                      <el-link
                          @click="goToDetails(content.id)"
                          target="_blank"
                          style="line-height: 2; color: #666"
                      >{{ content.title }}</el-link
                      >
                    </span>
                  <!--                    <span style="float: right; line-height: 2; color: #666">{{-->
                  <!--                        content.mmDdTime-->
                  <!--                      }}</span>-->
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import {findHomeConsult} from "/src/api/front/home";


export default {
  name: "news",
  data() {
    return {
      // 中部列表展示
      recommend: [
        {
          sysConsultType: {
            name: "",
          },
          sysConsults: [
            {
              netWorkUrl: "",
              mmDdTime: "",
            },
          ],
        },
      ],
    }
  },
  created() {
    this.load();
  },
  methods: {
    // 跳转到列表界面
    goToList(id) {
      const url = this.$router.resolve({path: "/front/edu/all", query: {id: id}});
      window.open(url.href, '_blank');
    },
    // 跳转到详情界面
    goToDetails(id) {
      const url = this.$router.resolve({path: `/front/edu/detail/${id}`});
      window.open(url.href, '_blank');

    },
    load() {
      findHomeConsult().then((res) => {
        this.recommend = res.data.data;
      });
    }
  }

};
</script>
<style lang="scss" scoped>
.borderHeader {
  color: #0080ff;
  font-size: 18px;
}

.center {
  .border {
    font-size: 18px;
    padding: 15px 15px 9px 15px;
    border-bottom: 1px solid #79bcff;
    background: #e1f0ff;
    color: #0080ff;
  }

  .more {
    float: right;
    font-size: 14px;
    color: #999999;
  }

  .step {
    position: relative;
  }

  .step:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5eacf9;
  }
}

::v-deep {
  .el-divider--horizontal {
    margin: 9px 0 24px 0;
    color: #d8d8d8;
  }

  .el-menu-item {
    padding: 0 5px;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 33px;
    line-height: 33px;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    color: #0080ff;
    border-bottom: 2px solid #0080ff;
  }

  .el-card.is-always-shadow,
  .el-card.is-hover-shadow:focus,
  .el-card.is-hover-shadow:hover {
    box-shadow: none;
  }

  .center {
    .el-card__body {
      padding: 0 0 15px 0;
    }
  }

  .footerBox {
    .el-card__body {
      padding: 15px;
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
